<template>
  <report filename="Estado de cuenta" report="account_statement">
    <template #filters="{ apply, loading, setData, setColumns, filterKey }">
      <report-filters
        :filter-key="filterKey"
        :loading="loading"
        :selected-columns.sync="arSelectedHeaders"
        currency-convert-empty-option
        hide-content-toggle
        hide-payment-type
        hide-currency-convert
        hide-movements-type
        show-include-cash
        show-include-previous-balance
        @apply="apply"
        @change="setData"
        @columns="setColumns"
        @reset="onSetInitFilterData"
      >
        <template #default="{ filters }">
          <v-row>
            <v-col cols="12" lg="4" md="6" v-if="false">
              <v-select
                v-model="filters.date_type"
                :items="[
                  { text: $t('date'), value: 'created_at' },
                  { text: $t('due.date'), value: 'due_date' },
                ]"
                :label="$t('date.type')"
                :menu-props="{ offsetY: true }"
                dense
                hide-details="auto"
                outlined
                @change="setData(filters)"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-btn-toggle
                v-model="filters.customer_type"
                color="primary"
                rounded
                @change="onChangeCustomerType"
              >
                <v-btn small value="customer">{{ $t("customer") }}</v-btn>
                <v-btn small value="provider" v-text="$t('provider')" />
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <customer-select
                v-model="filters.invoice_customer"
                :provider="providerIsSelected"
                multiple
              />
            </v-col>
          </v-row>
        </template>
      </report-filters>
    </template>
  </report>
</template>

<script lang="ts">
/* eslint-disable */ 
import { Component, Mixins } from "vue-property-decorator";
import ReportFilters from "@/modules/reports/components/ReportFilters.vue";
import Report from "@/modules/reports/components/Report.vue";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import { isArray } from "lodash";
import CustomerSelect from "@/modules/customers/components/CustomerSelect.vue";
import dayjs from "dayjs";
import { EventBus } from "@/services/event-bus";
import ReportFilterMixin from "@/modules/reports/mixins/ReportFilterMixin";

@Component({
  components: { CustomerSelect, Report, ReportFilters },
})
export default class AccountStatement extends Mixins(ReportFilterMixin) {
  arSelectedHeaders: DataTableHeader["value"][] = [
    "created_at",
    "invoice_type.short_name",
    "order_number",
    "payment_term",
    "due_date",
    "currency",
    "debit",
    "credit",
    "balance",
  ];

  get providerIsSelected(): boolean {
    return (
      !this.obFilterData.customer_type ||
      this.obFilterData.customer_type === "provider"
    );
  }

  setDefaultFilters() {
    //this.setFilter("credit", 1);
    this.setFilter("signed", 1);
    this.unsetFilter("unpaid");
    this.setFilter("show_content", 1);
    this.setFilter("no_check_skip_balance", 1);

    // unset(obData, "invoiceMovementType");
  }

  onSetInitFilterData() {
    this.setFilter("created_at", [dayjs().format("YYYY-MM-DD")]);
    this.setFilter("movementTypeCode", [1, 2, 5, 7]);
    this.setFilter("invoice_customer", []);
    this.setFilter("currency", 0);
    this.setFilter("currency_convert", 0);
    //this.setFilter("date_type", "created_at");
    this.setFilter("customer_type", "customer");
    this.setFilter("credit", 1);

    this.setDefaultFilters();
  }

  onSetFilterData() {
    this.setDefaultFilters();

    if (this.obFilterData.created_at) {
      const sDate = isArray(this.obFilterData.created_at)
        ? this.obFilterData.created_at.length > 1
          ? this.obFilterData.created_at[1]
          : this.obFilterData.created_at[0]
        : this.obFilterData.created_at;

      /*
      if (this.obFilterData.date_type === "due_date") {
        this.setFilter("due_date_to", sDate);
      } else {
        this.setFilter("created_at_to", sDate);
      }
      */

      //this.unsetFilter("created_at");
      this.unsetFilter("date_type");
      // unset(obData, "invoiceMovementType");
    }

    // this.setFilter("movementTypeCode", [
    //   this.obFilterData.customer_type === "customer" ? 1 : 8,
    // ]);
  }

  onChangeCustomerType(sValue: "customer" | "provider") {
    switch (sValue) {
      case "customer":
        this.setFilter("movementTypeCode", 1);
        break;
      case "provider":
        this.setFilter("movementTypeCode", 8);
        break;
    }
  }

  created() {
    this.setDefaultHeaders();
    this.addHeader({
      text: "report.invoice.total_price_value",
      value: "total_price_value",
    });
    this.addHeader({ text: "report.payment.terms", value: "payment_term" }, 2);
    this.addHeader({ text: "report.due.date", value: "due_date" }, 3);
    this.addHeader({ text: "report.invoice.debit", value: "debit" });
    this.addHeader({ text: "report.invoice.credit", value: "credit" });
    this.addHeader({ text: "report.invoice.balance", value: "balance" });
    this.removeHeader("taxes");
    EventBus.on("report.before.apply", this.onSetFilterData);
  }

  beforeDestroy() {
    EventBus.off("report.before.apply", this.onSetFilterData);
  }
}
</script>
